import React from "react";
import { Link } from "gatsby";
// import { Button } from "@/components/ui/button";
import EmbedForm from '@/components/EmbedForm';
// import { menuItems } from "./menuItems";
import { Button } from "@/components/ui/button"

// const FooterLinkSection = ({ title, links }) => (
//   <div className="space-y-4">
//     <h4 className="text-lg font-bold">{title}</h4>
//     <ul className="space-y-2 text-gray-400">
//       {links.map((link, index) => (
//         <li key={index}>
//           <Link to={link.to}>{link.name}</Link>
//         </li>
//       ))}
//     </ul>
//   </div>
// );

export default function Footer() {

  const footerbgImage = '/images/footer.webp';
  const artise = '/images/Sid-Domic_063.webp';

  return (
    <footer className="w-full relative bg-gray-200 text-black py-12 md:pb-16 md:pt-8"
    style={{
      backgroundImage: `url(${footerbgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-white via-white-70 to-white/50 backdrop-blur-md"></div>
      <section className="pb-5 relative z-10">
        <div className="w-full">
          <div className="flex items-center justify-center w-full">
            <div className="flex flex-col lg:flex-row items-center justify-center w-full gap-12">
              <div className="w-full h-[1000px] lg:w-1/2 pl-8 flex flex-col gap-10 order-2 lg:order-1 mb-10 lg:mb-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${artise})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              </div>
              <div className="w-full lg:w-1/2 p-8 order-1 lg:order-2" id="signup-index">
                <Link className="flex flex-col justify-center gap-3 mx-auto" to="/">
                  <img src="/CAETS2025.svg" alt="CAETS 2025 logo" width={500} />
                  <img src="/logo-dates.svg" alt="CAETS 2025 logo dates" width={500} />
                </Link>
                <h2 className="text-3xl font-bold mt-8 mb-4">Subscribe to our updates</h2>
                <EmbedForm />
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-6 container mx-auto md:mt-12">
          <div className="lg:w-3/12">
            <p>Above artwork</p>
            <h2 className="mb-0 pb-0">Generations</h2>
            <h2>by Sid Domic, Kalkatungu Man</h2>
          </div>
          <div className="lg:w-9/12">
            <p className="italic">“As with each generation we evolve, technology is also evolving. With this painting I like to
            acknowledge our Aboriginal ancestors and how they were connected with and understood
            country with intimate detail. As they cared for country, how they studied country and
            how they were a part of country. Their knowledges expanded to environmental sciences,
            engineering, marine biology, astronomy, health /healing, teaching / education.” --Sid Domic, Kalkatungu Man</p>
            <Button size="lg" className="text-lg bg-primary" asChild><a href="/artwork" rel="noreferrer">Read more about Sid's artwork</a></Button>
          </div>
        </div>
      </section>
      <div className="relative z-10 container mx-auto px-4 md:px-6 mt-8 text-center flex flex-col justify-center text-gray-700 pt-10 border-t border-slate-400">
      <p className="text-lg font-semibold my-4">CAETS 2025 is hosted by ATSE on behalf of CAETS</p>
      <div className="flex md:flex-row flex-col justify-center items-center gap-8">
        <a href="https://www.atse.org.au/" target="_blank" rel="noopener noreferrer" className="text-white"><img src="/atse-logo.png" alt="ATSE logo" width={280} /></a>
        <a href="https://www.newcaets.org/" target="_blank" rel="noopener noreferrer" className="text-white"><img src="/CAETS-logo.png" alt="CAETS logo" width={220} /></a>
      </div>
      </div>
      <div className="relative z-10 container mx-auto px-4 md:px-6 mt-8 text-center text-gray-700 pt-10">
      We acknowledge Traditional Owners of Country across Australia and recognise their continuing connection to land, water and community. We pay respect to Aboriginal and Torres Strait Islander culture, and Elders past and present.
      </div>
      <div className="relative z-10 container mx-auto px-4 md:px-6 mt-4 text-center text-gray-700 pt-10 ">
        © 2024 ICMSA. All rights reserved.
      </div>
    </footer>
  );
}
