import React, { useState } from 'react';
import { Link } from "gatsby";
import { DropdownMenuTrigger, DropdownMenuItem, DropdownMenuContent, DropdownMenu } from "@/components/ui/dropdown-menu";
import { ChevronDownIcon } from "./icons";
import NavItem from "./NavItem";
import { menuItems } from "./menuItems";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";

export default function NavBar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleToggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  const toggleSheet = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <nav className="hidden lg:flex space-x-4 relative w-full justify-around">
        {menuItems.header.map((item, index) => (
          item.dropdown ? (
            <DropdownMenu key={index}>
              <DropdownMenuTrigger
                className="flex items-center hover:text-white/60 cursor-pointer"
                onClick={() => handleToggleDropdown(index)}
              >
                {item.label}
                <ChevronDownIcon className="h-4 w-4 ml-1" />
              </DropdownMenuTrigger>
              {openDropdown === index && (
                <DropdownMenuContent className="absolute mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:right-[-50px]">
                  {item.items.map((subItem, subIndex) => (
                    <DropdownMenuItem key={subIndex} className="hover:bg-slate-100 p-0" onClick={handleCloseDropdown}>
                      { ( subItem.label == 'Prospectus') ? (
                        <a href="/downloads/CAETS2025-prospectus.pdf" target="_blank" rel="noopener noreferrer" className="w-full p-2">{subItem.label}</a>
                       ) : (
                        <Link to={subItem.to} className="w-full p-2">{subItem.label}</Link>
                       )
                      }
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              )}
            </DropdownMenu>
          ) : (
            <NavItem key={index} to={item.to}>{item.label}</NavItem>
          )
        ))}
      </nav>
      
      {/* Mobile Navbar */}
      <nav className="lg:hidden flex items-center">
        <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
          <SheetTrigger asChild>
            <button onClick={toggleSheet} aria-label="Open Menu">
              <Menu className="h-6 w-6" />
            </button>
          </SheetTrigger>
          <SheetContent side="right" className="w-10/12">
            <SheetHeader>
              <SheetTitle><h2 className="mb-0 pb-0">Menu</h2></SheetTitle>
            </SheetHeader>
            <div className="mt-4">
              {menuItems.header.map((item, index) => (
                <div key={index} className="my-2">
                  {item.dropdown ? (
                    <>
                      <button
                        className="w-full py-2 text-left flex items-center justify-between font-bold text-gray-600"
                        onClick={() => handleToggleDropdown(index)}
                      >
                        {item.label}
                        <ChevronDownIcon className="h-4 w-4" />
                      </button>
                      {openDropdown === index && (
                        <div className="mt-2 pl-4">
                          {item.items.map((subItem, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subItem.to}
                              className="flex py-1 text-gray-600"
                              onClick={() => setIsSheetOpen(false)}
                            >
                              {subItem.label}
                            </Link>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.to}
                      className="block py-2 text-gray-600 font-bold"
                      onClick={() => setIsSheetOpen(false)}
                    >
                      {item.label}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </SheetContent>
        </Sheet>
      </nav>
    </>
  );
}
