import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import NavBar from "./NavBar";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 1024) {
        if (window.scrollY > 0) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  let backgroundImage;
  let heading;
  let description;
  let headingstyle;

  if (location.pathname === "/") {
    backgroundImage = "/images/caets-banner.webp";
    heading = "Welcome";
    description = "";
  } else if (location.pathname === "/committee/") {
    backgroundImage = "/images/hero11.webp";
    heading = "Committee";
    description = "Meet the experts guiding our symposium towards a sustainable future.";
  } else if (location.pathname === "/conference/") {
    backgroundImage = "/images/hero8.webp";
    heading = "Conference";
    description = "Join global leaders to explore sustainable strategies for the next 50 years.";
  } else if (location.pathname === "/host/") {
    backgroundImage = "/images/hero2.webp";
    heading = "Host";
    description = "Discover our hosts dedicated to fostering sustainable practices.";
  } else if (location.pathname === "/accommodation/") {
    backgroundImage = "/images/hero3.webp";
    heading = "Accommodation";
    description = "Find the best places to stay during your event.";
  } else if (location.pathname === "/destination/") {
    backgroundImage = "/images/caets-hero-destination.webp";
    heading = "Destination";
    description = "Explore various destinations in Australia.";
    headingstyle = "bg-slate-100";
  } else if (location.pathname === "/venue/") {
    backgroundImage = "/images/sofitel-brisbane.webp";
    heading = "Venue";
    description = "Get all the details about the congress venue, including location and facilities.";
    headingstyle = "bg-[#0b0c0d] text-[#ffffff]";
  } else if (location.pathname === "/visa-information/") {
    backgroundImage = "/images/hero7.webp";
    heading = "Visa information";
    description = "Learn about visa requirements and application steps for traveling to Brisbane.";
  } else if (location.pathname === "/latest-news/") {
    backgroundImage = "/images/hero7.webp";
    heading = "Latest news";
    description = "Stay updated with the latest news and updates.";
  } else if (location.pathname === "/contact/") {
    backgroundImage = "/images/hero12.webp";
    heading = "Contact us";
    description = "For all enquiries, please contact the CAETS2025 Conference Secretariat. ";
  } else if (location.pathname === "/privacy-policy/") {
    backgroundImage = "/images/hero13.webp";
    heading = "Privacy policy";
    description = "Learn about our privacy policy and how we handle your personal data.";
  } else if (location.pathname === "/program/") {
    backgroundImage = "/images/hero14.webp";
    heading = "Program overview";
    description = "Learn about the program.";
  } else if (location.pathname === "/sponsorship/") {
    backgroundImage = "/images/Sofitel-Brisbane2.webp";
    heading = "Sponsorship";
    description = "Learn about the sponsorship opportunities available for your organisation.";
  } else if (location.pathname === "/artwork/") {
    backgroundImage = "/images/hero13.webp";
    heading = "Artwork";
    description = "Learn about the artwork created by Sid Domic, Kalkatungu Man.";
  } else if (location.pathname === "/registration/") {
    backgroundImage = "/images/hero7.webp";
    heading = "Registration";
    description = "Learn about the registration process.";
  }
  

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {!isHomePage && <div className="absolute inset-0 bg-white/30"></div>}
        <section className="relative z-20">
          <div className={`bg-blue-800/80 backdrop-blur-lg text-white font-semibold py-3 table md:w-full fixed ${isFixed ? "fixed-scroll" : "md:relative"} z-20 top-2 right-2 md:top-0 md:right-0 drop-shadow-lg`}>
            <div className="container mx-auto flex justify-center px-3 md:px-8">
              <NavBar />
            </div>
          </div>
        </section>

        <section className="relative z-10 container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className={`backdrop-blur-sm py-10 table ${isHomePage ? 'bg-white/90' : 'bg-white/50'}`}>
              <div className="relative flex items-center justify-between pb-10 px-6">
                <Link className="flex flex-col justify-center gap-3" to="/">
                  <img src="/CAETS2025.svg" alt="CAETS 2025 logo" width={500} />
                  <img src="/logo-dates.svg" alt="CAETS 2025 logo dates" width={500} />
                </Link>
              </div>
              <div className="px-6">
              <img src="/atse-logo.png" alt="ATSE" width={280} />
              </div>
              {/* <div className="grid grid-cols-1 px-6">
                <div className="space-y-6">
                  <h1 className="text-4xl font-bold leading-tight text-black sm:text-5xl md:text-6xl drop-shadow-lg">
                    {heading}
                  </h1> 
                  <p className="text-black text-lg font-semibold drop-shadow-lg">
                    {description}
                  </p>
                </div>
                <div className="relative rounded-lg overflow-hidden">
                  <div />
                </div>
              </div> */}
            </div>
          </div>
        </section>

      </header>
      <div className={headingstyle}>
      <div className="container mx-auto">
      <h1 className="text-4xl font-bold leading-tight sm:text-5xl md:text-6xl pt-12 md:pt-16 lg:pt-20 pb-6">
        {heading}
      </h1>
      </div>
      </div>
    </>
  );
}

export default Header;
