export const menuItems = {

    // header menu items
    header: [
      {
        label: "Home",
        to: "/",
        dropdown: false,
      },
      {
        label: "About",
        to: "",
        dropdown: true,
        items: [
          { label: "Conference", to: "/conference" },
          { label: "Host", to: "/host" },
          { label: "Committee", to: "/committee" },
        ],
      },
      {
        label: "Program",
        to: "",
        dropdown: true,
        items: [
          { label: "Program overview", to: "/program" },
        ],
      },
      {
        label: "Destination",
        to: "",
        dropdown: true,
        items: [
          { label: "Destination", to: "/destination" },
          { label: "Venue", to: "/venue" }
        ],
      },
      {
        label: "Registration",
        to: "",
        dropdown: true,
        items: [
          { label: "Registration", to: "/registration" },
          { label: "Visa information", to: "/visa-information" },
        ],
      },
      {
        label: "Sponsorship",
        to: "",
        dropdown: true,
        items: [
          { label: "Sponsorship", to: "/sponsorship" }
        ]
      },
      // {
      //   label: "Latest news",
      //   to: "/latest-news",
      //   dropdown: false,
      // },
      {
        label: "Contact",
        to: "",
        dropdown: true,
        items: [
          { label: "Contact us", to: "/contact" },
          { label: "Privacy policy", to: "/privacy-policy" },
        ],
      },
    ],






    // footer menu items
    footer: [
      {
        title: "About",
        links: [
          { name: "Our Story", to: "#" },
          { name: "Our Team", to: "#" },
          { name: "Careers", to: "#" },
        ],
      },
      {
        title: "Program",
        links: [
          { name: "Layouts", to: "#" },
          { name: "Templates", to: "#" },
          { name: "Themes", to: "#" },
        ],
      },
      {
        title: "Destination",
        links: [
          { name: "Documentation", to: "#" },
          { name: "Blog", to: "#" },
          { name: "Community", to: "#" },
        ],
      },
      {
        title: "Contact",
        links: [
          { name: "Support", to: "#" },
          { name: "Sales", to: "#" },
          { name: "Partnerships", to: "#" },
        ],
      },
    ],
  };
  