import React from 'react';

const EmbedForm = () => {
  return (
      <iframe
        id="contactform123"
        name="contactform123"
        title="Subscription form"
        className="w-full max-w-[600px] min-h-[720px] md:min-h-[580px]"
        src="https://form.123formbuilder.com/my-contact-form-6685603.html?customVars123=yes&hasEmbedFormStyle=1&hasTransparentBg=1"
      >
      </iframe>
  );
};

export default EmbedForm;